<template>
  <div id="container">

    <section id="maintenance">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="cts_ttl"><span>複眼経済塾は<br class="sp">現在メンテナンス中です</span></h2>
          <p class="lead_txt alnC">
            申し訳ございませんが、メンテナンス完了までお待ちください。
          </p>
          <div class="maintenance_box">
            <h3>メンテナンス完了まで全てのコンテンツをご利用頂けません。</h3>
            <div class="inner" v-if="true">
              2024年 9月25日（水）<br class="sp">15：30～16：30
            </div>
          </div>
        </div>
      </div>
    </section>

  </div><!--//＃container-->
</template>

<script>
export default {
  mounted() {
    if (this.$route.query.maintenance_test) {
      if (this.$route.query.maintenance_test === '1') {
        localStorage.setItem('maintenance_test', '1')
        this.$router.push({name: 'UsersCustomerTop'})
        return
      } else {
        localStorage.removeItem('maintenance_test')
      }
    }

    if (!process.env.VUE_APP_MAINTENANCE && !this.$route.query.debug) {
      if (this.$route.query.redirect) {
        this.$router.push({path: this.$route.query.redirect})
      } else {
        this.$router.push({name: 'UsersCustomerTop'})
      }
    }
  }
}
</script>
